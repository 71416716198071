import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Navbar.css";
import logo from "../assets/Images/Circle-Logo.svg";
import {
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate(); // Hook for navigation

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleNavigation = (path) => {
    // Navigate to the homepage if not already there
    if (window.location.pathname !== "/") {
      navigate("/");
    }
    // Scroll to the section after navigation
    setTimeout(() => {
      const sectionId = path.substring(1);
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  const toggleDrawer = (open) => (event) => {
    // Check if the event is defined and is a keyboard event
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <nav className="navbar">
      <Link to="/">
        <img
          src={logo}
          alt="Une Management Logo"
          className="navbar-logo"
          onClick={scrollToTop}
        />
      </Link>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        className="hamburger"
        onClick={(e) => toggleDrawer(true)(e)}>
        <MenuIcon />
      </IconButton>

      <Drawer
        className="mobile-menu"
        anchor={"top"}
        open={drawerOpen}
        onClose={(e) => toggleDrawer(false)(e)}>
        <List>
          <ListItem button onClick={() => toggleDrawer(false)()}>
            <Link to="/" onClick={scrollToTop}>
              <ListItemText primary="Home" />
            </Link>
          </ListItem>
          {/* <ListItem
            button
            onClick={() => {
              toggleDrawer(false)();
              handleNavigation("/services");
            }}>
            <ListItemText primary="Services" />
          </ListItem> */}
          <ListItem button onClick={() => toggleDrawer(false)()}>
            <Link to="/services" onClick={scrollToTop}>
              <ListItemText primary="Services" />
            </Link>
          </ListItem>
          <ListItem button onClick={() => toggleDrawer(false)()}>
            <Link to="/about" onClick={scrollToTop}>
              <ListItemText primary="About" />
            </Link>
          </ListItem>
          <ListItem
            button
            onClick={() => {
              toggleDrawer(false)();
              handleNavigation("/contact");
            }}>
            <ListItemText primary="Contact" />
          </ListItem>
        </List>
      </Drawer>

      <ul className="navbar-nav">
        <li>
          <Link to="/" onClick={scrollToTop}>
            Home
          </Link>
        </li>
        <li>
          <Link to="/services" onClick={scrollToTop}>
            Services
          </Link>
        </li>
        {/* <li>
          <button onClick={() => handleNavigation("/services")}>
            Services
          </button>
        </li> */}
        <li>
          <Link to="/about" onClick={scrollToTop}>
            About
          </Link>
        </li>
        <li>
          <button onClick={() => handleNavigation("/contact")}>Contact</button>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
