import React, { useState } from "react";
import logo from "../assets/Images/uneLogo.jpg";
import "./ComingSoon.css";

const ComingSoon = ({ onAccessGranted }) => {
  const [password, setPassword] = useState("");
  const [accessDenied, setAccessDenied] = useState(false);
  const correctPassword = "Lele01";

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (password === correctPassword) {
      onAccessGranted();
    } else {
      setAccessDenied(true);
    }
  };

  return (
    <div className="coming-soon-container">
      <img src={logo} alt="UNE Management Logo" className="coming-soon-logo" />
      <h2 className="coming-soon-text">Coming Soon</h2>
      {accessDenied && (
        <p className="access-denied">Access Denied. Incorrect Access Code.</p>
      )}
      <form onSubmit={handleFormSubmit} className="coming-soon-form">
        <input
          type="password"
          value={password}
          onChange={handlePasswordChange}
          placeholder="Enter Early Access code"
          className="coming-soon-input"
        />
        <button type="submit" className="coming-soon-button">
          Enter
        </button>
      </form>
    </div>
  );
};

export default ComingSoon;
