import React, { useState, useEffect, useRef } from "react";
import "./Home.css";
import conciergeService from "../assets/Images/Concierge-Service.png";
import discoveryLogo from "../assets/Images/meet.svg";
import proposalLogo from "../assets/Images/proposal.svg";
import achievementLogo from "../assets/Images/goal.svg";
import logo from "../assets/Images/uneLogo.svg";
import { Link } from "react-router-dom";
import { Card, CardContent, Typography, Grid } from "@material-ui/core";
const cardStyle = {
  minHeight: "350px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const Home = () => {
  const [isVisible, setIsVisible] = useState(false);
  const prevScrollPosRef = useRef(window.pageYOffset);
  const isScrollingDownRef = useRef(false);
  const imageRef = useRef(null);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    isScrollingDownRef.current = currentScrollPos > prevScrollPosRef.current;
    prevScrollPosRef.current = currentScrollPos;
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      if (entry.isIntersecting && isScrollingDownRef.current) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    });

    const currentRef = imageRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div className="home" id="home">
      <div className="background-container"></div>
      <div className="home-top">
        <div className="image-container">
          <img src={logo} alt="UNE Logo" />
        </div>
        <div className="welcome-and-image">
          <div className="welcom-body">
            <div className="banner-container">
              <div className="banner-text">
                <div>
                  <h1 className="whoWeAre">Who We Are</h1>
                </div>
                <p>
                  A Women led team that provides full financial service and
                  personal management. We understand that each talent, each
                  individual, each story is different. That's why we offer a
                  suite of services designed to meet the unique needs of our
                  clients across a wide range of sectors.
                </p>{" "}
              </div>
            </div>

            <div
              className={`image-business ${isVisible ? "drop-down" : ""}`}
              ref={imageRef}>
              <div>
                <h1 className="whoWeAre-services"> Services </h1>
              </div>
              <Link to="/services" onClick={scrollToTop}>
                <img src={conciergeService} alt="UNE Logo" />
              </Link>
              {/* <a
                href="/services"
                // onClick={(e) => {
                //   e.preventDefault();
                //   document
                //     .getElementById("services")
                //     .scrollIntoView({ behavior: "smooth" });
                // }}>
              >
                <img src={conciergeService} alt="UNE Logo" />
              </a> */}
            </div>
          </div>
        </div>
      </div>
      <div className="three-steps-container">
        <div className="three-step-title">
          <h2>Simple Three Step Process</h2>
        </div>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          className="card-grid"
          style={{
            marginTop: "0px",
            marginBottom: "-7%",
            backgroundColor: "#f9f9f8",
          }}>
          <Grid item xs={12} sm={4} className="fade-in-section">
            <Card className="initial-meeting">
              <CardContent style={cardStyle}>
                <img
                  src={discoveryLogo}
                  alt="Discovery"
                  style={{
                    maxWidth: "160px",
                    marginBottom: "10px",
                  }}
                />
                <Typography variant="h5" component="h2">
                  Initial Meeting & Discovery
                </Typography>
                <Typography variant="body1">
                  <div className="card-body">
                    <p>
                      We start with a friendly, in-person meeting to understand
                      your goals and ensure a good fit. We take a comprehensive
                      approach to assess your current situation.
                    </p>
                  </div>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} className="fade-in-section">
            <Card className="initial-meeting">
              <CardContent style={cardStyle}>
                <img
                  src={proposalLogo}
                  alt="Custom Proposal"
                  style={{
                    marginBottom: "10px",
                    maxWidth: "160px",
                  }}
                />
                <Typography variant="h5" component="h2">
                  Custom Proposal
                </Typography>
                <Typography variant="body1">
                  <div className="card-body">
                    <p>
                      We create a personalized and easy-to-understand service
                      proposal.
                    </p>
                  </div>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} className="fade-in-section">
            <Card className="initial-meeting">
              <CardContent style={cardStyle}>
                <img
                  src={achievementLogo}
                  alt="Goal Achievement"
                  style={{
                    marginBottom: "10px",
                    maxWidth: "160px",
                  }}
                />
                <Typography variant="h5" component="h2">
                  Goal Achievement
                </Typography>
                <Typography variant="body1">
                  <div className="card-body">
                    <p>
                      After agreement, we work closely with you to execute a
                      plan aligned with your objectives, prioritizing your peace
                      of mind.
                    </p>
                  </div>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Home;
