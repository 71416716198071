import React, { useEffect, useState } from "react";
import "./Services.css";
import estate from "../assets/Images/estate.png";
import bookkeeping from "../assets/Images/bookkeeping.png";
import tax from "../assets/Images/tax.png";
import payrol from "../assets/Images/payrol.png";
import domestic from "../assets/Images/domestic.png";
import event from "../assets/Images/event.png";
import { motion, useScroll, useTransform } from "framer-motion";
import { Card, CardContent, Grid, CardMedia } from "@material-ui/core";

const servicesList1 = [
  {
    image: bookkeeping,
    services: [
      "Bookkeeping Services",
      "Financial Process Improvement",
      "Financial Reporting",
      "Budgeting and Forecasting",
    ],
  },
  {
    image: tax,
    services: [
      "Tax Strategy and Planning",
      "Cash Flow Management",
      "Coordination of estate planning, wills, trusts, etc.",

      "Manage Banking Services",
    ],
  },
  {
    image: payrol,
    services: [
      "Payroll Services",
      "Assisting with insurance coverages.",
      "Assisting with credit acquisitions.",
      "Investment review in consultation with investment professional.",
    ],
  },
  {
    image: estate,
    services: [
      "Bill Pay Services",
      "Coordinating with industry specific advisors.",
      "Charitable planning and private foundation review.",
      "Income/salary reconciliation",
    ],
  },
];

const servicesList2 = [
  {
    image: domestic,
    services: [
      "Domestic Staffing Assistant",
      "Travel Coordination",
      "Real Estate",
      "Gift Sending",
      "Personal Chef",
      "Reservation Booking",
    ],
  },
  {
    image: event,
    services: [
      "Event Planning",
      "Private jet accommodations",
      "Security coordination",
      "Virtual Assistant",
      "Appointment Scheduling",
      "Calendar Management",
    ],
  },
];

const Services = () => {
  const { scrollYProgress } = useScroll();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Function to update the window width state
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // Add event listener on mount and clean up on unmount
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Define transforms without conditions
  const scaleLarge = useTransform(scrollYProgress, [0, 1], [1, 1.1]);
  const scaleSmall = useTransform(scrollYProgress, [0, 1], [0.9, 1.0]);
  const backgroundColor = useTransform(
    scrollYProgress,
    [0.2, 0.5, 1],
    ["#dde2c5", "#dde2c5", "#dde2c5"]
  );

  // Decide which scale to use based on the window width
  const scale = windowWidth > 768 ? scaleLarge : scaleSmall;
  return (
    <div className="services" id="services">
      {/* <h1 className="service-title">Services</h1> */}
      <h2 className="business-title">Financial Services</h2>
      <motion.div className="services-container" style={{ scale }}>
        {servicesList1.map((group, index) => (
          <Card key={index} className="service-card-list">
            <motion.div
              key={index}
              className="service-card-list"
              style={{ backgroundColor }}>
              <Grid container alignItems="center" spacing={0}>
                <Grid
                  item
                  xs={3}
                  style={{ textAlign: "center", padding: "0px" }}>
                  <CardMedia
                    component="img"
                    alt="Service Image"
                    style={{
                      height: "100px",
                      width: "auto",
                      margin: "0px",
                      opacity: "0.5",
                    }}
                    image={group.image}
                  />
                </Grid>
                <Grid item xs={9} style={{ padding: "0px" }}>
                  <CardContent style={{ padding: "4px", color: "black" }}>
                    <ul
                      style={{
                        listStyleType: "square",
                        textAlign: "left",
                        margin: "0px",
                        paddingLeft: "16px",
                      }}>
                      {group.services.map((service, sIndex) => (
                        <li
                          key={sIndex}
                          style={{ paddingLeft: "8px", margin: "1px" }}>
                          {service}
                        </li>
                      ))}
                    </ul>
                  </CardContent>
                </Grid>
              </Grid>
            </motion.div>
          </Card>
        ))}
      </motion.div>
      <h2 className="business-title" style={{ marginTop: "-2%" }}>
        Concierge Services
      </h2>
      <motion.div className="services-container" style={{ scale }}>
        {servicesList2.map((group, index) => (
          <Card key={index} className="service-card-list">
            <motion.div
              key={index}
              className="service-card-list"
              style={{ backgroundColor, transition: { delay: index * 0.2 } }}>
              <Grid container alignItems="center" spacing={0}>
                <Grid
                  item
                  xs={3}
                  style={{ textAlign: "center", padding: "0px" }}>
                  <CardMedia
                    component="img"
                    alt="Service Image"
                    style={{
                      height: "100px",
                      width: "auto",
                      margin: "0px",
                      opacity: "0.5",
                    }}
                    image={group.image}
                  />
                </Grid>
                <Grid item xs={9} style={{ padding: "0px" }}>
                  <CardContent style={{ padding: "4px", color: "black" }}>
                    <ul
                      style={{
                        listStyleType: "square",
                        textAlign: "left",
                        margin: "0px",
                        paddingLeft: "16px",
                      }}>
                      {group.services.map((service, sIndex) => (
                        <li
                          key={sIndex}
                          style={{ paddingLeft: "8px", margin: "1px" }}>
                          {service}
                        </li>
                      ))}
                    </ul>
                  </CardContent>
                </Grid>
              </Grid>
            </motion.div>
          </Card>
        ))}
      </motion.div>
    </div>
  );
};

export default Services;
