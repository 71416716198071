import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@material-ui/core";
import "./About.css";
import founderImage from "../assets/Images/cari.jpg";

const About = () => {
  return (
    <div className="about" id="about">
      <h1>Founder BIO</h1>
      <Grid
        container
        justify="center"
        spacing={0}
        className="founder-container">
        <Grid item xs={12} md={4}>
          <Card className="founder-card">
            <CardMedia
              image={founderImage}
              title="Cari Costa"
              className="founder-image"
            />
            <CardContent>
              <Typography variant="h5" component="h2">
                Josie Samson
              </Typography>
              <Typography variant="body1" className="about-founder">
                <span className="about-founder">
                  Josie Samson, a founding member of UNE Management, brings over
                  six years of hands-on experience in business management. She
                  excels in handling financial services like bookkeeping and
                  accounts management while smoothly managing day-to-day
                  operations. Josie's commitment to excellence shines through in
                  her tailored solutions for clients from different backgrounds.
                  With a strategic approach, she optimizes business processes to
                  ensure sustained growth for her clients.
                </span>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card className="founder-card">
            <CardMedia
              image={founderImage}
              title="Cari Costa"
              className="founder-image"
            />
            <CardContent>
              <Typography variant="h5" component="h2">
                Lele Akalu
              </Typography>
              <Typography variant="body1" className="about-founder">
                <span className="about-founder">
                  Lele Akalu, co-founder of UNE Management is a seasoned
                  professional with over ten years of dedicated experience as an
                  executive assistant, specializing in catering to high-profile
                  clientele. With a keen eye for detail and a knack for seamless
                  organization, Lele has provided invaluable support to
                  prominent individuals throughout her career. Passionate about
                  delivering top-tier service, she has honed her skills in
                  managing and prioritizing tasks efficiently.
                </span>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card className="founder-card">
            <CardMedia
              image={founderImage}
              title="Cari Costa"
              className="founder-image"
            />
            <CardContent>
              <Typography variant="h5" component="h2">
                Cari Costa
              </Typography>
              <Typography variant="body1" className="about-founder">
                <span className="about-founder">
                  Cari Costa is a founding member of UNE management with 15
                  years in the fashion and service industry. She led the North
                  American launch of Kate Spade Saturday and introduced American
                  Eagle Outfitters to China/Hong Kong. She's a board member for
                  Communities in School Los Angeles, lives in Santa Monica with
                  her husband and two sons, and enjoys traveling, crafting,
                  sports events, and family time. Cari holds a Bachelor of Arts
                  from Chico State University.
                  <span className="space"></span>
                </span>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default About;
