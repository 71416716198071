import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ComingSoon from "./ComingSoon/ComingSoon";
import Home from "./Home/Home";
import About from "./About/About";
import Services from "./Services/Services";
import Contact from "./Contact/Contact";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";

function App() {
  const [hasAccess, setHasAccess] = useState(false);

  const grantAccess = () => {
    setHasAccess(true);
  };

  return (
    <Router>
      {!hasAccess ? (
        <ComingSoon onAccessGranted={grantAccess} />
      ) : (
        <>
          <Navbar />
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Home id="home" />
                  {/* <Services id="services" /> */}
                  <Contact id="contact" />
                </>
              }
            />
            <Route path="/services" element={<Services />} />
            <Route path="/about" element={<About />} />
          </Routes>
          <Footer />
        </>
      )}
    </Router>
  );
}

export default App;
