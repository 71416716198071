import React, { useState, useRef } from "react";
import { FaInstagram } from "react-icons/fa";
import emailjs from "emailjs-com";
import { TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  IoLocation,
  IoPhonePortraitOutline,
  IoMailOutline,
} from "react-icons/io5";
import "./Contact.css";

const serviceId = process.env.REACT_APP_SERVICE_ID;
const templateId = process.env.REACT_APP_TEMPLATE_ID;
const userId = process.env.REACT_APP_EMAILJS_USER_ID;

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "Black",
  },
  inputField: {
    margin: theme.spacing(1),
    width: "49%", // Or '100%' if you want it to be responsive
    "& label.Mui-focused": {
      color: "black", // Label color when the input is focused
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black", // Border color of the input field
      },
      "&:hover fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
    [theme.breakpoints.down("sm")]: {
      // Adjust 'sm' to your mobile breakpoint
      width: "92%", // 95% width on mobile devices
    },
  },
  submitButton: {
    margin: theme.spacing(2),
    width: "40%",
    backgroundColor: "black",
    color: "white",
    "&:hover": {
      backgroundColor: "black",
      opacity: "0.3",
    },
  },
  submitMessage: {
    marginTop: theme.spacing(2),
    color: "green",
  },
}));

function Contact() {
  const classes = useStyles();
  const [submitMessage, setSubmitMessage] = useState("");
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(serviceId, templateId, e.target, userId).then(
      (result) => {
        console.log(result.text);
        setSubmitMessage("Message sent successfully!");
        e.target.reset();
        setTimeout(() => {
          setSubmitMessage("");
        }, 5000);
      },
      (error) => {
        console.log(error.text);
        setSubmitMessage(
          "Failed to send the message, please try again or use email reach us."
        );
        setTimeout(() => {
          setSubmitMessage("");
        }, 10000);
      }
    );
  };

  return (
    <div className="contact" id="contact">
      {/* <h1 className="contact-title">Contact Us</h1> */}
      <div className="contact-container">
        <div className="get-in-touch">
          <h2 className="keep-in-touch">Let's Get In Touch</h2>
          <form className={classes.form} onSubmit={sendEmail} ref={form}>
            <div className="inputRow">
              <TextField
                label="Name"
                name="from_name"
                required
                variant="outlined"
                className={classes.inputField}
              />
              <TextField
                label="Email"
                name="from_email"
                required
                variant="outlined"
                className={classes.inputField}
                type="email"
              />
            </div>
            <TextField
              label="Message"
              name="message"
              required
              variant="outlined"
              className={classes.inputField}
              multiline
              rows={2}
            />
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={classes.submitButton}>
              Send
            </Button>
            {submitMessage && (
              <p className={classes.submitMessage}>{submitMessage}</p>
            )}
          </form>
        </div>
        <div className="social-logos">
          <a
            href="https://instagram.com/unemanagement/"
            target="_blank"
            rel="noopener noreferrer">
            <FaInstagram
              size={30}
              style={{
                border: "solid #e0d4cc 1px",
                padding: "14px",
                borderRadius: "50%",
              }}
            />
          </a>
          <a
            href="http://maps.google.com/?q=3019 Wilshire Blvd suite 162 Santa Monica, CA 90302"
            target="_blank"
            rel="noopener noreferrer">
            <IoLocation
              size={30}
              style={{
                border: "solid #e0d4cc 1px",
                padding: "14px",
                borderRadius: "50%",
              }}
            />
          </a>
          <a
            href="mailto:unemgmt@gmail.com"
            target="_blank"
            rel="noopener noreferrer">
            <IoMailOutline
              size={30}
              style={{
                border: "solid #e0d4cc 1px",
                padding: "14px",
                borderRadius: "50%",
              }}
            />
          </a>
          {/* <a href="tel:7144206250" target="_blank" rel="noopener noreferrer"></a> */}
          <a target="_blank" rel="noopener noreferrer">
            <IoPhonePortraitOutline
              size={30}
              style={{
                border: "solid #e0d4cc 1px",
                padding: "14px",
                borderRadius: "50%",
              }}
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contact;
